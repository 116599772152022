/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorPotentialBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { bookingsListFilterOptions, loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store/index.js'

/**
 * Action to load translator potential bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {int} payload.page - The page offset to be displayed.
 * @param {int} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default function (context, payload = {}) {
  const isPresumptive = !!payload.presumptive_potential_bookings
  let url = `${API.BOOKINGS}/potential`
  let list = 'setTranslatorPotentialBookingList'
  let loading = 'setTranslatorPotentialBookingListLoading'
  let include = [
    // 'customer',
    // 'translator_levels',
    'specific_translators',
    // 'excluded_translators',
    // 'convey.files',
    // 'text_translation.files',
    // 'messages.files',
    'video_booking',
    'booking_group.bookings',
    // 'translator_trainings',
    'booking_time_slots.translator.translator_unavailable_times',
    'batch_entry'
  ]

  const topControls = store.getters['bookingList/topControls']
  const append = ['acceptable_booking_dates']
  if (isPresumptive) {
    url = `${API.BOOKINGS}/presumptive-potential`
    list = 'setTranslatorPresumptivePotentialBookingList'
    loading = 'setTranslatorPresumptivePotentialBookingListLoading'
    include.push('presumptive_translator')
  }

  context.commit(loading, true)
  include = include.join(',')

  const method = 'GET'
  const fields = [
    'id,customer_id,due,duration,from_language_id,to_language_id,status_id,is_immediate,gender,type,created_at,reference,booker_name,additional_field_label,additional_field,additional_field_two_label,additional_field_two,staff_name,instructions,address,ended_at,will_end_at,booking_group_id,parent_booking_id,staff_phone'
  ].join(',')

  let options = {
    query_params: {
      include,
      // 'paginator': 'simple',
      'paginator': payload.paginator,
      'fields[bookings]': fields,
      append
    },

    on_finally(ctx) {
      ctx.commit(loading, false)
    }
  }

  let emergency = topControls?.is_immediate ?? payload?.is_immediate ?? null

  if (emergency) {
    options.query_params['filter[is_immediate]'] = emergency
  } else {
    delete options.query_params['filter[is_immediate]']
  }
  let type = topControls?.type ?? payload?.type ?? null
  if (type) {
    payload.filter_list_query = type
  }
  const filterQueries = bookingsListFilterOptions(payload, {
    exclude: ['customer_id']
  })

  assignIn(options.query_params, filterQueries)

  return loadAll(this, context, url, method, list, options, axiosInstance)
}
