/*
|--------------------------------------------------------------------------
| Store > Booking List > State
|--------------------------------------------------------------------------
|
| This file is where the Module's state properties are registered.
|
| Feel free to edit this file if you have state properties you want
| to include.
|
*/

import cloneDeep from 'lodash/cloneDeep'

const topControlsFresh = {
  filter_list_by: 'id',
  filter_list_query: '',
  sort_by: 'due',
  sort_order: 'asc',
  filter_list_date_from: '',
  filter_list_date_to: '',
  customer_id: '',
  booking_statuses: [],
  show_department_bookings: false
}

const topControlsFormOptions = {
  booking_status_list: []
}

export default function () {
  return {
    topControls: cloneDeep(topControlsFresh),
    topControlsFresh: cloneDeep(topControlsFresh),
    topControlsFormOptions,
    purchaseManagerSector: {
      sector_type: 'municipality',
      sector_id: 0
    }
  }
}
