/*
|--------------------------------------------------------------------------
| Store > Auth > Getters
|--------------------------------------------------------------------------
|
| This file is where the Module's getter methods are registered.
|
| Feel free to edit this file if you have getter methods you want
| to include.
|
*/

import USER_TYPE from '@/modules/constants/userType'
import BOOKING_TYPE from '@/modules/constants/bookingType'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import sumBy from 'lodash/sumBy'
import find from 'lodash/find'
import remove from 'lodash/remove'
import appStorage from '@/modules/helpers/appStorage.js'
import { USER_BOOKING_TYPE } from '@/modules/constants/localStorage.js'

/**
 * @param {object} state
 * @param {string} bookingType
 * @returns {boolean}
 */
function isEnabledBookingType(state, bookingType) {
  const userMeta = state.userMeta.data

  const bookingTypes = userMeta?.booking_types ?? appStorage.get(USER_BOOKING_TYPE) ?? []
  return some(bookingTypes, { booking_type: bookingType })
}

export default {
  /**
   * return state.userSessionLogs.data
   *
   * @param state
   * @returns {{}}
   */
  userSessionLogsData: (state) => state.userSessionLogs.data,

  /**
   * return state.userSessionLogs.loading
   *
   * @param state
   * @returns {boolean}
   */
  userSessionLogsLoading: (state) => state.userSessionLogs.loading,

  /**
   * Determine if there is a token in localStorage
   *
   * @returns {boolean}
   */
  isUserAuthenticated: (state) => state.isUserAuthenticated,

  /**
   * is this user a self registered one
   * @param {*} state
   * @returns {boolean}
   */
  isSelfRegistered: (state) => state.userMeta.data.is_self_registered,

  isVirtualMunicipality: (state) => state.userMeta.data.is_virtual_municipality,

  /**
   * is payment setup for the self registered user.
   */
  isPaymentSetupForSelfRegisteredUser: (state) => {
    return state.userMeta.data.is_payment_setup
  },
  /**
   * it's a b2b customer.
   */
  isB2BCustomer: (state) => {
    return state.userMeta.data.is_b2b_customer
  },
  /** get credit worthiness */
  creditWorthiness: (state) => {
    return state.creditWorthiness
  },
  /** show credit unworthiness banner */
  showCreditUnworthinessBanner: (state) => {
    return (
      state.userMeta.data.is_b2b_customer &&
      state.userMeta.data.is_virtual_municipality &&
      !state.creditWorthiness?.is_credit_worthy &&
      state.userMeta.data.customer_data?.payment_method_type == 'dt_invoice'
    )
  },
  /**show booking charge message */
  showBookingChargeMessage: (state) => {
    return (
      state.userMeta.data.is_self_registered &&
      state.userMeta.data.is_virtual_municipality &&
      state.userMeta.data.is_payment_setup &&
      state.userMeta.data.customer_data?.payment_method_type != 'dt_invoice'
    )
  },
  /** needs price quote */
  needsPriceQuote: (state) => {
    return state.userMeta.data.is_self_registered && state.userMeta.data.is_virtual_municipality
  },
  /**
   * get payment method type from customer_data
   */
  customerPaymentMethodType: (state) => {
    return state.userMeta.data.customer_data?.payment_method_type
  },
  /**
   * Determine if the authenticated user is a Customer.
   *
   * @returns {boolean}
   */
  isUserCustomer: (state) => state.user.type === USER_TYPE.CUSTOMER,

  /**
   * Determine if the authenticated user is a Translator.
   *
   * @returns {boolean}
   */
  isUserTranslator: (state) => state.user.type === USER_TYPE.TRANSLATOR,

  /**
   * Determine if the authenticated user is a Admin.
   *
   * @returns {boolean}
   */
  isUserAdmin: (state) => state.user.type === USER_TYPE.ADMIN || state.user.type === USER_TYPE.SUPER_ADMIN,

  /**
   * Determine if the authenticated user is an employed translator.
   *
   * @returns {boolean}
   */
  isUserEmployedTranslator: (state, getters) => getters.isUserTranslator && state.isEmployedTranslator,

  /**
   * @returns {boolean}
   */
  isEnabledTypePhysical: (state) => isEnabledBookingType(state, BOOKING_TYPE.PHYSICAL),

  /**
   * @returns {boolean}
   */
  isEnabledTypePhone: (state) => isEnabledBookingType(state, BOOKING_TYPE.PHONE),

  /**
   * @returns {boolean}
   */
  isEnabledTypeVideo: (state) => isEnabledBookingType(state, BOOKING_TYPE.VIDEO),

  /**
   * @returns {boolean}
   */
  isEnabledTypeVideoPhysical: (state) => isEnabledBookingType(state, BOOKING_TYPE.VIDEO_PHYSICAL),

  /**
   * @returns {boolean}
   */
  isEnabledTypeVideoSkype: (state) => isEnabledBookingType(state, BOOKING_TYPE.VIDEO_SKYPE),
  /**
   * @returns {boolean}
   */
  isEnabledTypeConvey: (state) => isEnabledBookingType(state, BOOKING_TYPE.COVEY),

  /**
   * @returns {boolean}
   */
  isEnabledTextTranslation: (state) => isEnabledBookingType(state, BOOKING_TYPE.TEXT_TRANSLATION),

  /**
   * @returns {object}
   */
  user: (state) => state.user,

  /**
   * @returns {object}
   */
  userMetaData: (state) => state.userMeta.data,
  /**
   * @returns {boolean}
   */
  isEnabledUserFax(state) {
    if (
      !isNil(state.userMeta.data) &&
      !isEmpty(state.userMeta.data) &&
      !isNil(state.userMeta.data.customer_notification_types) &&
      !isEmpty(state.userMeta.data.customer_notification_types)
    ) {
      const notifs = state.userMeta.data.customer_notification_types
      const fax = find(notifs, (o) => o.notification_type === 'fax')
      return !isNil(fax) && fax.enabled
    } else {
      return false
    }
  },

  /**
   * @returns {boolean}
   */
  isEnabledUserDownloadInvoices(state) {
    return (
      !isNil(state.userMeta.data) &&
      !isEmpty(state.userMeta.data) &&
      !isNil(state.userMeta.data.customer_data) &&
      !isEmpty(state.userMeta.data.customer_data) &&
      !isNil(state.userMeta.data.customer_data.allow_show_invoices) &&
      state.userMeta.data.customer_data.allow_show_invoices
    )
  },

  /**
   * @returns {boolean}
   */
  isLoadingUserMeta: (state) => state.userMeta.loading,

  /**
   * @returns {boolean}
   */
  isLoadingLogin: (state) => state.isLoadingLogin,

  /**
   * @returns {boolean}
   */
  isLoadingRegister: (state) => state.isLoadingRegister,

  /**
   * @returns {boolean}
   */
  isLoadingRequestResetPassword: (state) => state.isLoadingRequestResetPassword,

  /**
   * @returns {boolean}
   */
  isLoadingVerifyResetPassword: (state) => state.isLoadingVerifyResetPassword,

  /**
   * @returns {boolean}
   */
  isShowLoginModal: (state) => state.isShowLoginModal,

  /**
   * @returns {boolean}
   */
  isShowTextTranslationLoginModal: (state) => state.isShowTextTranslationLoginModal,

  /**
   * Returns boolean whether the user is eligible to view/show
   * Department Scope Bookings.
   *
   * @returns {boolean}
   */
  isAllowShowDepartmentScopeBookings(state) {
    const userMeta = state.userMeta.data

    if (
      !isEmpty(userMeta) &&
      !isNil(userMeta.customer_data) &&
      !isNil(userMeta.customer_data.department) &&
      !isNil(userMeta.customer_data.department.disallow_users_view_bookings)
    ) {
      return !userMeta.customer_data.department.disallow_users_view_bookings
    } else {
      return false
    }
  },

  /**
   * @returns {object}
   */
  userFeedbacksData: (state) => state.userFeedbacks.data,

  /**
   * @returns {int}
   */
  userFeedbacksRateAverage(state) {
    if (!isNil(state.userFeedbacks.data) && !isEmpty(state.userFeedbacks.data)) {
      const sum = sumBy(state.userFeedbacks.data, 'rating')
      const ave = sum / state.userFeedbacks.data.length
      return Math.round(ave * 10) / 10
    } else {
      return 0
    }
  },

  /**
   * @returns {boolean}
   */
  isLoadingUserFeedbacks: (state) => state.userFeedbacks.loading,

  // --------------------------------------------------
  // Translator Opts
  // --------------------------------------------------

  /**
   * @returns {object}
   */
  translatorOpts: (state) => state.translatorOpts.data,

  /**
   * @returns {boolean}
   */
  isLoadingTranslatorOpts: (state) => state.translatorOpts.loading,

  // --------------------------------------------------
  // Translator Level Opts
  // --------------------------------------------------

  /**
   * @returns {object}
   */
  translatorLevelOpts: (state) => state.translatorLevelOpts.data,

  /**
   * @returns {boolean}
   */
  isLoadingTranslatorLevelOpts: (state) => state.translatorLevelOpts.loading,

  /**
   * @returns {object}
   */
  departmentUserOpts: (state) => state.departmentUserOpts.data,

  /**
   * @returns {object}
   */
  companyUserOpts: (state) => state.companyUserOpts.data,

  /**
   * @returns {object}
   */
  municipalityUserOpts: (state) => state.municipalityUserOpts.data,

  /**
   * @returns {boolean}
   */
  isLoadingDepartmentUserOpts: (state) => state.departmentUserOpts.loading,

  /**
   * @returns {object}
   */
  departmentOpts: (state) => state.departmentOpts.data,

  /**
   * @returns {boolean}
   */
  isLoadingDepartmentOpts: (state) => state.departmentOpts.loading,

  /**
   * @returns {object}
   */
  companyOpts: (state) => state.companyOpts.data,

  /**
   * @returns {boolean}
   */
  isLoadingCompanyOpts: (state) => state.companyOpts.loading,

  /**
   * @returns {object}
   */
  townOpts: (state) => state.townOpts.data,

  /**
   * @returns {boolean}
   */
  isLoadingTownOpts: (state) => state.townOpts.loading,

  /**
   * @returns {object}
   */
  customerTypeOpts: (state) => state.customerTypeOpts.data,

  /**
   * @returns {boolean}
   */
  isLoadingCustomerTypeOpts: (state) => state.customerTypeOpts.loading,

  /**
   * @return {boolean}
   */
  isOnMobileViewport: (state) => state.isOnMobileViewport,

  /**
   * @return {boolean}
   */
  isShowMobileNav: (state) => state.isShowMobileNav,

  /**
   * @returns {boolean}
   */
  isEnabledUserSMS(state) {
    if (state.isUserAdmin) {
      // show fields for admin mode
      return true
    } else if (
      !isNil(state.userMeta.data) &&
      !isEmpty(state.userMeta.data) &&
      !isNil(state.userMeta.data.customer_notification_types) &&
      !isEmpty(state.userMeta.data.customer_notification_types)
    ) {
      const notifs = state.userMeta.data.customer_notification_types
      const sms = find(notifs, (o) => o.notification_type === 'sms')
      return !isNil(sms) && sms.enabled
    } else {
      return false
    }
  },

  isShowEmailCheckbox(state) {
    if (state.user.type === USER_TYPE.CUSTOMER) {
      return state.userMeta?.data?.customer_data?.show_email_checkbox ?? false
    }
    return false
  },

  /**
   * @returns {object}
   */
  userFeedbacksPagination: (state) => state.userFeedbacks.pagination,

  countries: (state) => state.countries,
  /**
   * @returns {boolean}
   */
  isEnabledVideo: (state) =>
    !state.isUserAuthenticated ||
    isEnabledBookingType(state, BOOKING_TYPE.VIDEO) ||
    isEnabledBookingType(state, BOOKING_TYPE.VIDEO_SKYPE) ||
    isEnabledBookingType(state, BOOKING_TYPE.VIDEO_PHYSICAL),

  isEnabledVideoEmergency: (state) =>
    !state.isUserAuthenticated || isEnabledBookingType(state, BOOKING_TYPE.VIDEO_EMERGENCY),

  /**
   * @param state
   * @return {array}
   */
  authenticatedUsers(state) {
    if (!isNil(state.user) && !isEmpty(state.user)) {
      const removed = remove(state.authenticatedUsers, (user) => {
        return user.id === state.user.id
      })

      state.authenticatedUsers.unshift(removed[0])
    }

    return state.authenticatedUsers
  },

  hasAuthenticatedUsers(state) {
    return !isNil(state.authenticatedUsers) && !isEmpty(state.authenticatedUsers)
  },

  /**
   * @param state - Vuex context state
   * @returns {Object[]}
   */
  translatorSalaryFiles: (state) => state.translatorSalaryFiles,

  disallowedTranslatorCustomerTypes: (state) => state.disallowedTranslatorCustomerTypes,

  /**
   * @returns {object}
   */
  trainingOpts: (state) => state.trainingOpts.data,

  /**
   * @returns {boolean}
   */
  isLoadingTrainingOpts: (state) => state.trainingOpts.loading,
  /**
   * @param state - Vuex context state
   * @returns {Object[]}
   */
  userFiles: (state) => state.userFiles,
  videoSettings: (state) => state.videoSettings,
  /**
   * @returns {boolean}
   */
  isDisabledNonVocalLanguages(state) {
    const userMeta = state.userMeta.data
    if (
      !isEmpty(userMeta) &&
      !isNil(userMeta.customer_data) &&
      !isNil(userMeta.customer_data.department) &&
      !isNil(userMeta.customer_data.department.company) &&
      !isNil(userMeta.customer_data.department.company.municipality)
    ) {
      return userMeta.customer_data.department.company.municipality.disable_non_vocal_languages
    }
    return false
  },

  customerMunicipalityId(state) {
    const userMeta = state.userMeta.data
    return userMeta?.customer_data?.department?.company?.municipality?.id
  },

  getUserSurveys: (state) => state.surveys,
  blackListedTranslatorsForCustomerAndDept(state) {
    const blus = []
    const blusIds = []
    for (let i = 0; i < state.userMeta.data.blacklisted_users?.length; i++) {
      const blu = state.userMeta.data.blacklisted_users[i]
      blu['blocked_for_dept'] = false
      blu['blocked_for_me'] = true
      for (let j = 0; j < state.userMeta.data.blacklisted_departments?.length; j++) {
        const bld = state.userMeta.data.blacklisted_departments[j]
        if (blu.translator_id == bld.translator_id) {
          blu['blocked_for_dept'] = true
          break
        }
      }
      blus.push(blu)
      blusIds.push(blu.translator_id)
    }

    for (let i = 0; i < state.userMeta.data.blacklisted_departments?.length; i++) {
      const bld = state.userMeta.data.blacklisted_departments[i]
      if (!blusIds.includes(bld.translator_id)) {
        bld['blocked_for_dept'] = true
        bld['blocked_for_me'] = false
        blus.push(bld)
        blusIds.push(bld.translator_id)
      }
    }

    return blus
  },
  canShowRegisterFeatureImage(state) {
    return state.registerPage.showFeatureImage
  },
  registerFeatureImageType(state) {
    return state.registerPage.featureImageType
  },
  /**
   * @returns {boolean}
   */
  isAutoPriceEnabled(state) {
    if (!isNil(state.userMeta?.data?.customer_data?.department)) {
      return state.userMeta.data.customer_data.department.company.municipality.asking_price_enabled
    }
    return false
  },
  /**
   * @returns {boolean}
   */
  isInstantTranslationEnabled(state) {
    if (!isNil(state.userMeta?.data?.customer_data?.department)) {
      return state.userMeta.data.customer_data.department.company.municipality.instant_translation_enabled
    }
    return false
  },
  /**
   * @returns {boolean}
   */
  isManualTranslationEnabled(state) {
    if (!isNil(state.userMeta?.data?.customer_data?.department)) {
      return state.userMeta.data.customer_data.department.company.municipality.manual_translation_enabled
    }
    return false
  },
  /**
   * @returns {number}
   */
  getInstantTranslationsCount(state) {
    if (!isNil(state.userMeta?.data)) {
      return state.userMeta.data.instant_translations_count
    }
    return 0
  },
  /**
   * @returns {number}
   */
  getAllowedInstantTranslationCount(state) {
    if (!isNil(state.userMeta?.data?.customer_data?.department)) {
      return state.userMeta.data.customer_data.department.company.municipality.instant_translation_count
    }
    return 0
  },
  isPopupShown(state) {
    return state.popupShown
  },
  fallbackBookingType(state) {
    if (!isNil(state.userMeta?.data?.customer_data?.department?.company?.booking_fallback_type)) {
      return state.userMeta.data.customer_data.department.company.booking_fallback_type
    }
    return 'phone'
  },
  isPurchasingManager(state) {
    return state.userMeta.data.is_purchase_manager
  },
  /**
   * Coming from guest link
   * @param {*} state
   * @returns {boolean}
   */
  isGuestMode(state) {
    return (
      state.user.username !== undefined && state.user.username === import.meta.env.VITE_DEFAULT_GUEST_CUSTOMER_USERNAME
    )
  },

  bookingTypes(state) {
    return state.userMeta?.booking_types ?? appStorage.get(USER_BOOKING_TYPE) ?? []
  }
}
