/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > loadDepartmentUserOpts
|--------------------------------------------------------------------------
|
*/

import { loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action to load the department opts.
 *
 * @param {object} context - Current context of the Vuex module.
 * @param {object} payload - Contains necessary values usable within the method.
 * @returns {Promise|*}
 */
export default function (context) {
  context.commit('auth/setCustomerTypeOptsLoading', true, { root: true })

  const url = `/api/v3/core/customer-types-for-ct`
  const method = 'GET'

  let options = {
    query_params: {
      sort: 'name',
      all: true
    },
    on_finally(ctx) {
      ctx.commit('auth/setCustomerTypeOptsLoading', false, { root: true })
    }
  }

  return loadAll(this, context, url, method, 'setCustomerTypeOpts', options, axiosInstance)
}
