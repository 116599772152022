/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > initPusher
|--------------------------------------------------------------------------
|
*/

// noinspection ES6UnusedImports
import WsClass from '@/modules/helpers/ws'
import EVENT from '@/modules/constants/event'
import EventBus from '@/modules/helpers/eventBus'
import { showSmallErrorNotif, showSmallSuccessNotif } from '@/modules/helpers/notifications'

// Define the notification audio.
let audio = null

if (import.meta.client) {
  audio = new Audio('/assets/audio/notify.mp3')
}

// Define helper for playing the notification audio.
function playAudio() {
  if (audio !== null) audio.play()
}

export default function (context) {
  const isEnableSocket = parseInt(import.meta.env.VITE_SOCKET_ENABLED)

  if (!isEnableSocket) return

  const user = context.state.user

  context.state.pusher = new WsClass({ user })
  context.state.pusher.connect()

  context.state.pusher
    .subscribe([`user.${user.id}`, EVENT.FE_VERSIONING])
    .listen(EVENT.BOOKING_CREATED, (event) => EventBus.emit(EVENT.BOOKING_CREATED, event))
    .listen(EVENT.BOOKING_UPDATED, (event) => EventBus.emit(EVENT.BOOKING_UPDATED, event))
    .listen(EVENT.BOOKING_POTENTIAL, (event) => EventBus.emit(EVENT.BOOKING_POTENTIAL, event))
    .listen(EVENT.BOOKING_NOT_MATCH, (event) => EventBus.emit(EVENT.BOOKING_NOT_MATCH, event))
    .listen(EVENT.BOOKING_ACCEPTED, (event) => {
      EventBus.emit(EVENT.BOOKING_ACCEPTED, event)
      console.log('accepted', event)
      showSmallSuccessNotif(`Tolkning ${event.booking.id} tillsatt med tolk`)
    })
    .listen(EVENT.BOOKING_ACCEPTED_BY_ADMIN, (event) => {
      EventBus.emit(EVENT.BOOKING_ACCEPTED_BY_ADMIN, event)
      if (event?.booking?.is_immediate && ['video', 'video_skype'].includes(event?.booking?.type)) {
        EventBus.emit('translator-auto-assign-booking', event)
      }
    })
    .listen(EVENT.BOOKING_STARTED_REMINDER, (event) => EventBus.emit(EVENT.BOOKING_STARTED_REMINDER, event))
    .listen(EVENT.BOOKING_STARTED, (event) => EventBus.emit(EVENT.BOOKING_STARTED, event))
    .listen(EVENT.BOOKING_WITHDRAWN, (event) => EventBus.emit(EVENT.BOOKING_WITHDRAWN, event))
    .listen(EVENT.BOOKING_LATE_CANCEL, (event) => EventBus.emit(EVENT.BOOKING_LATE_CANCEL, event))
    .listen(EVENT.BOOKING_CANCELLED, (event) => EventBus.emit(EVENT.BOOKING_CANCELLED, event))
    .listen(EVENT.BOOKING_EXPIRED, (event) => {
      EventBus.emit(EVENT.BOOKING_EXPIRED, event)
      playAudio()
      showSmallErrorNotif(`Tolkning ${event.booking.id} har utgått`)
    })
    .listen(EVENT.BOOKING_ENDED, (event) => EventBus.emit(EVENT.BOOKING_ENDED, event))
    .listen(EVENT.BOOKING_FEEDBACK_REMINDER, (event) => EventBus.emit(EVENT.BOOKING_FEEDBACK_REMINDER, event))
    .listen(EVENT.BOOKING_SESSION_REMINDER, (event) => EventBus.emit(EVENT.BOOKING_SESSION_REMINDER, event))
    .listen(EVENT.BOOKING_FEEDBACK_CREATED, (event) => EventBus.emit(EVENT.BOOKING_FEEDBACK_CREATED, event))
    .listen(EVENT.BOOKING_SESSION_UPDATED, (event) => EventBus.emit(EVENT.BOOKING_SESSION_UPDATED, event))
    // Text Translations
    .listen(EVENT.TEXT_TRANSLATION_CAN_RESUBMIT, (event) => EventBus.emit(EVENT.TEXT_TRANSLATION_CAN_RESUBMIT, event))
    .listen(EVENT.TEXT_TRANSLATION_BOOKING_COMPLETED, (event) =>
      EventBus.emit(EVENT.TEXT_TRANSLATION_BOOKING_COMPLETED, event)
    )
    .listen(EVENT.TEXT_TRANSLATION_FILE_RESUBMITTED, (event) =>
      EventBus.emit(EVENT.TEXT_TRANSLATION_FILE_RESUBMITTED, event)
    )
    .listen(EVENT.TEXT_TRANSLATION_BOOKING_DEADLINE_REMINDER, (event) =>
      EventBus.emit(EVENT.TEXT_TRANSLATION_BOOKING_DEADLINE_REMINDER, event)
    )
    .listen(EVENT.TEXT_TRANSLATION_COST_SUBMISSION, (event) =>
      EventBus.emit(EVENT.TEXT_TRANSLATION_COST_SUBMISSION, event)
    )

    .listen(EVENT.BOOKING_EXPIRED_FROM_COUNTER, (event) => {
      EventBus.emit(EVENT.BOOKING_EXPIRED_FROM_COUNTER, event)
      playAudio()
      showSmallErrorNotif('Booking Expired from Counter')
    })
    .listen(EVENT.USER_BOOKING_STATISTICS, (event) => EventBus.emit(EVENT.USER_BOOKING_STATISTICS, event))
    .listen(EVENT.BOOKING_ISSUE_EVENTS_REMINDER, (event) => EventBus.emit(EVENT.BOOKING_ISSUE_EVENTS_REMINDER, event))
    .listen(EVENT.BOOKING_ISSUE_CUSTOMER_REPORT, (event) => EventBus.emit(EVENT.BOOKING_ISSUE_CUSTOMER_REPORT, event))
    .listen(EVENT.BOOKING_NOT_PICKING_UP_WARNING, (event) => EventBus.emit(EVENT.BOOKING_NOT_PICKING_UP_WARNING, event))
    .listen(EVENT.BOOKING_PENALTY, (event) => EventBus.emit(EVENT.BOOKING_PENALTY, event))
    .listen(EVENT.BLOG_CREATED, (event) => EventBus.emit(EVENT.BLOG_CREATED, event))
    .listen(EVENT.SURVEY_CREATED, (event) => EventBus.emit(EVENT.SURVEY_CREATED, event))
    .listen(EVENT.USER_INBOX, (event) => EventBus.emit(EVENT.USER_INBOX, event))
    .listen(EVENT.NOTIFICATION_MARK_SINGLE_READ, (event) => EventBus.emit(EVENT.NOTIFICATION_MARK_SINGLE_READ, event))
    .listen(EVENT.NOTIFICATION_MARK_ALL_REGULAR_READ, (event) =>
      EventBus.emit(EVENT.NOTIFICATION_MARK_ALL_REGULAR_READ, event)
    )
    .listen(EVENT.NOTIFICATION_MARK_ALL_INBOX_READ, (event) =>
      EventBus.emit(EVENT.NOTIFICATION_MARK_ALL_INBOX_READ, event)
    )
    .listen(EVENT.LEAVE_REQUEST_APPROVED, (event) => EventBus.emit(EVENT.LEAVE_REQUEST_APPROVED, event))
    .listen(EVENT.LEAVE_REQUEST_REJECTED, (event) => EventBus.emit(EVENT.LEAVE_REQUEST_REJECTED, event))
    .listen(EVENT.TRANSLATOR_TRAVEL_TIME_REJECTED, (event) =>
      EventBus.emit(EVENT.TRANSLATOR_TRAVEL_TIME_REJECTED, event)
    )
    .listen(EVENT.TRANSLATOR_TRAVEL_TIME_ACCEPTED, (event) =>
      EventBus.emit(EVENT.TRANSLATOR_TRAVEL_TIME_ACCEPTED, event)
    )
    .listen(EVENT.INVOICE_COMPRESSED, (event) => {
      EventBus.emit(EVENT.INVOICE_COMPRESSED, event)
    })
    .listen(EVENT.FE_VERSIONING_UPDATED, (event) => {
      EventBus.emit(EVENT.FE_VERSIONING_UPDATED, event)
    })

  // if(!isNil(import.meta.env.VITE_HANDESK_PUSHER_KEY)) {
  //   window.Pusher = require('pusher-js');
  //
  //   window.Echo = new Echo({
  //     broadcaster: 'pusher',
  //     key: import.meta.env.VITE_HANDESK_PUSHER_KEY,
  //     cluster: 'eu',
  //     encrypted: true
  //   });
  //
  //
  //   let channel = `ticketCommented.${user.id}`;
  //
  //   window.Echo.channel(channel)
  //     .listen('.ticket.commented', (e) => {
  //       showSmallNotif(
  //         'blue',
  //         {
  //           title: 'Du har nytt meddelande,',
  //           duration:7000,
  //           message: `ärendenummer #${e.comment.ticket_id}`,
  //           custom_icon: 'el-icon-message',
  //           on_click: () => {
  //             router.push({name:'emails.details',params:{id:e.comment.ticket_id}})
  //           }
  //         }
  //       )
  //   })
  //
  //
  // }
}
