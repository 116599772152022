<template>
  <div v-loading="loading" class="app-form" :class="getBem(blockClass)">
    <el-form ref="form" :model="iValue" :rules="formRules" @submit.native.prevent>
      <!-- Session Time -->

      <template v-if="isShowSessionTimeButton(booking)">
        <h6>{{ $t('session_time') }}</h6>
        <el-form-item
          :class="getBem(blockClass, ['session-time-container'])"
          :label="$t('booking_session_time')"
          prop="session_time"
        >
          <el-input v-model="iValue.session_time" :class="getBem(blockClass, ['session-time-field'])" />

          <span :class="getBem(blockClass, ['session-time-helper'])">HH:mm:ss</span>
        </el-form-item>

        <!-- Session Time -->
        <el-form-item
          v-if="isWillPayBreak(booking)"
          :class="getBem(blockClass, ['lunch-break-container'])"
          :label="$t('lunch_break')"
          prop="break_minutes"
        >
          <el-select
            v-model="iValue.break_minutes"
            :class="getBem(blockClass, ['lunch-break-field'])"
            :placeholder="$t('select_break_minutes')"
          >
            <el-option v-for="item in breakMinutes" :key="item.value" :value="item.value" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item :class="getBem(blockClass, ['comment-container'])" :label="$t('comment')" prop="comment">
          <el-input v-model="iValue.comment" :class="getBem(blockClass, ['comment-field'])" type="textarea" :rows="4" />
        </el-form-item>
      </template>
      <!--      End Session time-->

      <!--Travel time-->
      <template v-if="isShowTravelTimeButton(booking)">
        <h6>{{ $t('btn_travel_time') }}</h6>

        <el-form-item
          :class="getBem(blockClass, ['mode-of-travel-container'])"
          :label="$t('how_did_you_travel')"
          prop="traveled_by"
        >
          <el-radio-group
            v-model="iValue.traveled_by"
            :class="getBem(blockClass, ['mode-of-travel-group'])"
            @change="handleChangeTravelOption"
          >
            <el-radio label="transit">
              <div :class="getBem(blockClass, ['mode-of-travel'])">
                <span>{{ $t('public_transportation') }}</span>
              </div>
            </el-radio>

            <el-radio label="driving">
              <div :class="getBem(blockClass, ['mode-of-travel'])">
                <span>{{ $t('car') }}</span>
              </div>
            </el-radio>

            <el-radio label="bike">
              <div :class="getBem(blockClass, ['mode-of-travel'])">
                <span>{{ $t('bike') }}</span>
              </div>
            </el-radio>

            <el-radio label="walk">
              <div :class="getBem(blockClass, ['mode-of-travel'])">
                <span>{{ $t('walk') }}</span>
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          :class="getBem(blockClass, ['mode-of-travel-container'])"
          :label="$t('two_way_title')"
          prop="way_of_travel"
        >
          <el-radio-group
            v-model="iValue.way_of_travel"
            :class="getBem(blockClass, ['mode-of-travel-group'])"
            @change="handleChangeTravelOption"
          >
            <el-radio label="two_way">
              <div :class="getBem(blockClass, ['mode-of-travel'])">
                <span>{{ $t('two_way') }}</span>
              </div>
            </el-radio>
            <el-radio label="one_way">
              <div :class="getBem(blockClass, ['mode-of-travel'])">
                <span>{{ $t('one_way') }}</span>
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          v-if="iValue.suggested_travel_time != null"
          :class="getBem(blockClass, ['travel-time'])"
          :label="$t('suggested_travel_time')"
        >
          <booking-time-field
            v-model="iValue.suggested_travel_time"
            :class="getBem(blockClass, ['suggested-time'])"
            placeholder="HH:mm"
            :disabled="true"
          />

          <span :class="getBem(blockClass, ['time-helper'])">HH:mm </span>
        </el-form-item>

        <el-form-item :class="getBem(blockClass, ['travel-time'])" :label="$t('total_travel_time')" prop="time">
          <booking-time-field
            v-model="iValue.time"
            :class="getBem(blockClass, ['time'])"
            placeholder="HH:mm"
            @change="handleChangeTravelTime"
            @native-click="handleNativeClickTravelTime"
          />

          <span :class="getBem(blockClass, ['time-helper'])">HH:mm </span>
          <div v-if="iValue.way_of_travel == 'two_way'" class="text-muted">
            {{ $t('travel_time_including_return') }}
          </div>
          <div v-if="showTimeError" class="custom-error el-form-item__error">
            {{ $t('municipality_max_traveltime', { name: municipality.name }) }}
          </div>
        </el-form-item>
        <el-form-item
          v-if="iValue.is_discrepancy"
          :class="getBem(blockClass, ['comment-container'])"
          :label="$t('discrepancy_reason')"
          prop="discrepancy_reason"
        >
          <el-input
            v-model="iValue.discrepancy_reason"
            :class="getBem(blockClass, ['comment-field'])"
            type="textarea"
            :rows="4"
          />
        </el-form-item>

        <el-form-item
          v-if="iValue.traveled_by === 'driving'"
          :class="getBem(blockClass, ['travel-km'])"
          :label="$t('translator_max_travel_km')"
          prop="travel_distance"
        >
          <el-input
            v-model="iValue.travel_distance"
            placeholder="KM"
            :class="getBem(blockClass, ['travel-km-field'])"
          />
          <span :class="getBem(blockClass, ['travel-km-helper'])">KM </span>
        </el-form-item>

        <el-form-item
          v-if="showInconvenience"
          :class="getBem(blockClass, ['travel-during-unsocial-hours'])"
          :label="$t('travel_during_unsocial_hours')"
          prop="inconvenience.quantity"
        >
          <el-input
            v-model="iValue.inconvenience.quantity"
            :class="getBem(blockClass, ['travel-during-unsocial-hours-field'])"
          />
        </el-form-item>

        <el-form-item :class="getBem(blockClass, ['comment-container'])" :label="$t('comment')" prop="comment">
          <el-input
            v-model="iValue.comment_travel_time"
            :class="getBem(blockClass, ['comment-field'])"
            type="textarea"
            :rows="4"
          />
        </el-form-item>

        <!-- End Travel time-->
      </template>
      <br />
      <!--  Show travel time erro -->
      <template v-if="isShowTravelTimeError(booking)">
        <h6>{{ $t('btn_travel_time') }}</h6>
        <div :class="getBem(blockClass, 'travel-time-card')">
          <el-card>
            <el-row>
              <el-col :md="22">
                <b>{{ $t('dt_exeptions_travel_time_report_same_month') }}</b>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </template>
      <br />
      <!-- Action Buttons -->
      <div :class="getBem(blockClass, 'actions')" class="text-right">
        <el-button
          class="app-button-default"
          :class="getBem(blockClass, ['close-button'])"
          tabindex="0"
          @click="handleClickClose"
        >
          {{ $t('btn_close') }}
        </el-button>

        <el-button
          :class="getBem(blockClass, ['submit-button'])"
          class="app-button-secondary"
          tabindex="0"
          :disabled="loading"
          @click="handleClickSubmit"
        >
          {{ $t('btn_submit') }}
        </el-button>
      </div>
    </el-form>
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import BookingTimeField from '@/modules/booking/components/forms/BookingTimeField'
import isNil from 'lodash/isNil'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'
import momentJs from 'moment'

export default {
  components: {
    BookingTimeField
  },

  /*
    |--------------------------------------------------------------------------
    | Props >
    |--------------------------------------------------------------------------
    */
  props: {
    booking: {
      type: Object,
      default() {
        return {}
      }
    },
    modelValue: {
      type: Object,
      required: true
    }
  },

  emits: ['update:modelValue', 'submit', 'close'],

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'booking-session-travel-time-form',
      notificationType: 'fax',
      showInconvenience: false,
      showTravelTimeButton: null,
      showTimeError: false,
      municipality: {},
      form: {},
      breakMinutes: [
        {
          value: 0,
          name: this.$t('no_break')
        },
        {
          value: 30,
          name: '0 - 30 mins'
        },
        {
          value: 60,
          name: '30 - 60 mins'
        }
      ],
      loading: false
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    /**
     * Interface for this.value.
     */
    iValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    formRules() {
      return {
        session_time: [
          {
            required: true,
            message: this.getRequiredRuleMsg('session_time'),
            trigger: 'change'
          },
          { validator: this.validateTimeFormat, trigger: 'change' }
        ],
        time: [
          { validator: this.validateTimeFormatSimple, trigger: 'change' },
          { validator: this.validateMaxTravelTime, trigger: 'change' }
        ],
        travel_distance: [{ validator: this.validateTravelNumber, trigger: 'change' }],
        discrepancy_reason: [
          {
            required: this.iValue.is_discrepancy,
            message: this.getRequiredRuleMsg('discrepancy_reason'),
            trigger: 'change'
          }
        ]
      }
    },

    /**
     * @returns {boolean}
     */
    isUserTranslator() {
      return this.$store.getters['auth/isUserTranslator']
    }
  },

  watch: {
    booking: function () {
      this.showInconvenience = false
      this.showTravelTimeButton = null
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    ...mapActions('booking', ['loadSalaryPreview', 'getSuggestedTravelTime']),

    /**
     * Helper for required fields condition.
     *
     * @returns {Boolean}
     */
    requiredConditionHelper(v) {
      return !isNil(v) && trim(v) !== ''
    },
    /**
     * @returns {void}
     */
    handleChangeTravelTime() {
      this.iValue.travel_time = moment.duration(this.iValue.time).asMinutes()
      //if municipality region skane

      if (!isNil(this.iValue.suggested_travel_time)) {
        let start = moment.duration(this.iValue.time).asMinutes()
        let end = moment.duration(this.iValue.suggested_travel_time).asMinutes()
        const difference = start - end
        const percentageDifference = (difference / end) * 100

        if (percentageDifference > 50 && percentageDifference < 200) {
          this.$confirm(this.$t('travel_time_difference_warning'), this.$t('warning'), {
            confirmButtonText: this.$t('edit'),
            cancelButtonText: this.$t('continue'),
            type: 'warning'
          })
            .then(() => {
              this.iValue.time = this.iValue.suggested_travel_time
            })
            .catch(() => {
              this.iValue.is_discrepancy = 1
            })
        } else if (percentageDifference >= 200) {
          this.iValue.time = this.iValue.suggested_travel_time
          this.$alert(this.$t('warning_travel_time_very_high'), '', {
            confirmButtonText: this.$t('close')
          })
        } else {
          this.iValue.is_discrepancy = 0
        }
      }
    },
    handleChangeTravelOption() {
      this.loading = true
      this.iValue.is_discrepancy = 0
      this.iValue.discrepancy_reason = ''
      if (this.iValue.traveled_by == 'bike' || this.iValue.traveled_by == 'walk') {
        this.iValue.time = '00:00'
        this.iValue.suggested_travel_time = null
        // this.iValue.use_suggested_travel_time = false
        // this.iValue.travel_time = moment.duration(this.iValue.time).asMinutes()
        this.loading = false
        return
      } else {
        this.getSuggestedTravelTime({
          traveled_by: this.iValue.traveled_by,
          booking_id: this.booking.id,
          two_way_trip: this.iValue.way_of_travel === 'two_way'
        }).then((response) => {
          // * 2 for round trip - will be managed backend side
          const duration = moment.duration(response.data.data.suggestions.temp_travel_time, 'minutes')
          this.iValue.time = '00:00'
          this.iValue.suggested_travel_time = moment.utc(duration.asMilliseconds()).format('HH:mm')
          // this.iValue.use_suggested_travel_time = true
          // this.iValue.travel_time = moment.duration(this.iValue.time).asMinutes()
          this.loading = false
        })
      }
    },
    handleNativeClickTravelTime(el) {
      if (isNil(this.iValue.time) || this.iValue.time === '') {
        this.scrollMethod(el)
      }
    },

    scrollMethod(el) {
      const opts = el.options
      for (let o of opts) {
        if (o.value === '07:00') {
          o.$el.scrollIntoView(true)
          window.scrollTo(0, 0)
          break
        }
      }
    },

    getInvalidDateFormatMsg(key) {
      const value = String(this.$t(key)).toLowerCase()
      return this.$t('time_format_invalid', { value })
    },

    getInvalidFormatMsg(key) {
      const value = String(this.$t(key)).toLowerCase()
      return this.$t('format_invalid', { value })
    },

    /**
     * Helper method for producing a required rules message.
     *
     * @param key - Key for i18n locale.
     * @returns {string}
     */
    getRequiredRuleMsg(key) {
      const value = String(this.$t(key)).toLowerCase()
      return this.$t('is_required', { value })
    },

    /**
     * Returns boolean wether to show the travel time button.
     *
     * @param {Object} booking - Object to be evaluated.
     * @returns {Boolean}
     */
    isShowTravelTimeButton(booking) {
      console.log(booking.travel_time)
      if (!isNil(booking.travel_time) && booking.travel_time.travel_time_update_counter >= 3) {
        this.showTravelTimeButton = false
        return false
      }
      if (!isNil(this.showTravelTimeButton) && (this.iValue.pay_travel_time || this.iValue.pay_travel_distance)) {
        return this.showTravelTimeButton
      }
      const isSameMonth = moment(booking.due).isSame(moment(), 'month')

      if (
        (isSameMonth || booking.travel_time == null) &&
        (this.iValue.pay_travel_time || this.iValue.pay_travel_distance) &&
        (booking.status.code === 'completed' || booking.status.code === 'not_carried_out_customer') &&
        ['physical', 'video_physical'].includes(booking.type)
      ) {
        this.iValue.submit_travel_time = true
        this.isShowInconvenience(this.booking)
        this.showTravelTimeButton = true
        return true
      }
      this.showTravelTimeButton = false
      return false
    },

    /**
     * Returns boolean wether to show the edit session button.
     *
     * @param {Object} booking - Object to be evaluated.
     * @returns {Boolean}
     */
    isShowSessionTimeButton(booking) {
      const isSameMonth = moment(booking.due).isSame(moment(), 'month')
      if (isSameMonth && booking.status.code === 'completed') {
        this.iValue.submit_session_time = true
        return true
      }
      return false
    },

    handleClickClose() {
      this.$emit('close')
    },

    handleClickSubmit: debounce(function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.validateSessionTime() === false) {
            this.$confirm(this.$t('session_time_longer_message'), this.$t('warning'), {
              confirmButtonText: this.$t('YES'),
              cancelButtonText: this.$t('cancel'),
              type: 'warning'
            })
              .then(() => {
                this.$emit('submit')
              })
              .catch(() => {
                return false
              })
          } else {
            this.$emit('submit')
          }
        } else {
          return false
        }
      })
    }, 750),
    /**
     * check if reported session time is longer than the booking duration
     * @returns {boolean}
     */
    validateSessionTime() {
      if (this.isUserTranslator == false) {
        return true
      }
      if (this.isShowSessionTimeButton(this.booking)) {
        const sessionTimeMinutes = momentJs.duration(this.iValue.session_time).asMinutes()
        if (sessionTimeMinutes > this.booking.duration) {
          return false
        }
      }

      return true
    },

    /**
     * @param v
     * @returns {boolean}
     */
    validateTimeFormat(rule, value, callback) {
      const isValidTime = moment(value, 'HH:mm:ss', true).isValid()

      if (!isValidTime) {
        callback(new Error(this.getInvalidFormatMsg('session_time')))
      } else {
        callback()
      }
    },

    /**
     * @param v
     * @returns {boolean}
     */
    validateTimeFormatSimple(rule, value, callback) {
      const isValidTime = moment(value, 'HH:mm', true).isValid()

      if (!isValidTime) {
        callback(new Error(this.getInvalidFormatMsg('travel_time')))
      } else {
        callback()
      }
    },

    validateMaxTravelTime(rule, value, callback) {
      this.showTimeError = false
      let municipality = this.booking?.customer?.department?.company?.municipality ?? { id: null, name: '' }
      this.municipality = municipality
      let municipalityId = [424, 4]
      if (municipalityId.includes(municipality.id)) {
        if (moment.duration(value).asMinutes() === 120) {
          this.showTimeError = true
          callback()
        }
        if (moment.duration(value).asMinutes() > 120) {
          callback(new Error(this.$t('municipality_exceed_traveltime', { name: municipality.name })))
        }
      }

      callback()
    },

    validateTravelNumber(rule, value, callback) {
      if (isNaN(value)) {
        callback(new Error(this.getInvalidFormatMsg('travel_distance')))
      } else {
        callback()
      }
    },

    isWillPayBreak(booking) {
      return booking.salary_settings.length > 0 && booking.salary_settings[0].salary_setting.will_pay_break
    },

    async isShowInconvenience(booking) {
      if (
        booking.salary_settings.length > 0 &&
        booking.salary_settings[0].salary_setting.travel_setting.pay_inconvenience_travel_time
      ) {
        this.showInconvenience = await this.loadSalaryPreview({
          id: booking.id
        }).then((response) => {
          let data = response.data.data
          return data.salaries_items.some((salary_item) => {
            if (String(salary_item.type) === 'inconvenience-travel-time-fee') {
              this.iValue.inconvenience.type = salary_item.type
              this.iValue.inconvenience.name = salary_item.name
              this.iValue.inconvenience.description = salary_item.description
              this.iValue.inconvenience.price = salary_item.price
              this.iValue.inconvenience.unit = salary_item.unit
              this.iValue.inconvenience.quantity = salary_item.quantity
              this.iValue.submit_inconvenience = true
              return true
            }
          })
        })
      }
    },

    isShowTravelTimeError(booking) {
      return !isNil(booking.travel_time) && booking.travel_time.travel_time_update_counter >= 3
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';

.booking-session-travel-time-form {
  a,
  a:hover,
  a:focus,
  a:visited,
  a:active {
    color: var(--app-primary-color);
    text-decoration: underline;
  }

  &__travel-km-field {
    width: 80% !important;
  }

  &__session-time-field {
    width: 80% !important;
  }

  &__travel-km-helper,
  &__time-helper,
  &__session-time-helper {
    border: 1px solid #dcdfe6;
    line-height: 40px;
    background-color: #dcdfe6;
    text-align: center;
    width: 80px !important;
    display: inline-block;
    flex-grow: 1;
  }

  &__time {
    width: 80% !important;
  }

  &__suggested-time {
    width: 80% !important;

    .el-input {
      .el-input__wrapper {
        input {
          background: transparent;
          font-weight: 400;
          font-size: 14px;
          color: #bcc1cc;
        }
      }
    }
  }

  &__mode-of-travel {
    display: inline-block;
  }

  .el-form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: row;

    & > .el-form-item__label {
      width: 140px;
      line-height: normal;
      word-break: keep-all;
      text-align: left;
      font-weight: 600;
      font-size: 12px;
      align-items: center;
    }

    & > .el-form-item__content {
      flex-grow: 1;

      .el-select,
      .el-input {
        width: 100%;
      }
    }

    .custom-error {
      position: unset !important;
    }
  }

  h6 {
    color: var(--app-primary-color);
    margin-bottom: 18px;
    font-size: 15px;
  }

  &__form-errors {
    border: 1px solid $app-danger-color;
    background-color: lighten($app-danger-color, 50%);
    color: $app-danger-color;
    margin-bottom: 25px;
  }

  &__travel-time-card {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .text-muted {
    color: #999999;
  }
}
</style>
