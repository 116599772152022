import axios from 'axios'
import appStorage from '@/modules/helpers/appStorage'
import LS from '@/modules/constants/localStorage'
import { getTenantBaseApiUrl, getAppTenant } from '@/modules/helpers/multiTenancy'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
// import {AUTH} from '@/modules/constants/api.js'
import store from '@/store'
// import router from '@/router/index.js'

// const CancelToken = axios.CancelToken
// let cancel
export const axiosInstance = axios.create()

export function getBaseUrl() {
  // some logic to determine base URL here
  return Promise.resolve(getTenantBaseApiUrl())
}

axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl()
    const token = appStorage.get(LS.TOKEN)
    let user = appStorage.get(LS.USER)
    let accessToken = ''
    let tokenExpiry = ''
    let isValidToken = false
    if (token) {
      if (isString(token)) {
        accessToken = token
      } else if (isObject(token)) {
        accessToken = token.access_token
      } else {
        console.error('Unknown token format!')
      }

      tokenExpiry = getExpirationTime(accessToken)

      if (tokenExpiry) {
        const currentTime = new Date().getTime()
        const expirationTime = tokenExpiry * 1000
        if (currentTime < expirationTime) {
          isValidToken = true
        }
      }
      if (!isValidToken) {
        window.location.href = '/login'
        logout(user?.id)
        return
        // return Promise.reject(new Error('Token invalid! Redirecting to login page...'))
      }

      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    const appTenant = getAppTenant()
    if (appTenant && appTenant.uuid) {
      config.headers['X-Tenant'] = appTenant.uuid
    }
    return {
      ...config
      // cancelToken: new CancelToken(function executor() {
      //   // cancel = c
      // })
    }
  },
  async (error) => {
    const originalRequest = error.config
    console.log('originalRequest', originalRequest)
    return await Promise.reject(error)
  }
)

function getExpirationTime(jwtToken) {
  if (!jwtToken) {
    return null // Token is missing
  }

  const [, payloadBase64] = jwtToken.split('.')
  const payload = JSON.parse(atob(payloadBase64))

  return payload?.exp ?? false
}

function logout(userId) {
  if (userId) {
    store.dispatch('auth/removeFromAuthCollection', userId, {
      root: true
    })
  }
}
