<template>
  <div v-if="isShowVersionAlert" :class="getBem(blockClass)">
    <el-row :class="getBem(blockClass, ['alert-cookie-usage'])" justify="center" align="middle">
      <el-col :xs="24" :sm="24" :md="24">
        <div :class="getBem(blockClass, ['alert-cookie-usage', 'wrapper'])">
          <span :class="getBem(blockClass, ['alert-cookie-usage', 'verbiage'])" @click="handleClickClose">
            {{ $t('new_version_updated') }}
          </span>
          <a
            :class="getBem(blockClass, ['alert-cookie-usage', 'close'])"
            class="app-button-secondary"
            href="#"
            @click.prevent.stop="handleClickClose"
          >
            Refresh
          </a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import appStorage from '@/modules/helpers/appStorage.js'

export default {
  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'version-alert-partial',
      isShowVersionAlert: false,
      feVersion: ''
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > mounted
    |--------------------------------------------------------------------------
    */
  mounted() {},

  /*
    |--------------------------------------------------------------------------
    | Component > method
    |--------------------------------------------------------------------------
    */
  methods: {
    /**
     * Handler when the close (X) button was clicked.
     *
     * @returns {void}
     */
    handleClickClose() {
      this.isShowVersionAlert = false
      appStorage.set('fe_version', this.feVersion)
      location.reload()
    },
    show(e) {
      this.feVersion = e.updated_at
      this.isShowVersionAlert = true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.version-alert-partial {
  &__alert-cookie-usage {
    width: 100%;
    height: 75px;
    padding: 0 50px;
    margin-bottom: 7px;
    border-radius: 3px;
    color: $app-lighter-gray;
    border-bottom: 1px solid $app-lightest-gray;

    &__wrapper {
      position: relative;

      & > a,
      & > a:focus,
      & > a:hover,
      & > a:visited,
      & > a:active {
        color: #fff;
      }
    }

    &__verbiage {
      font-weight: normal;
      display: inline-block;
      cursor: pointer;
      color: $app-primary-color;
    }

    &__close {
      position: absolute;
      top: -12px;
      right: 1px;
      background-color: var(--app-primary-color);
      color: #fff;
      display: inline-block;
      padding: 12px 50px;
    }
  }
}

// **************************************************
// Extra Small Viewport
// **************************************************
@media (max-width: $screen-xs-max) {
  .cookie-alert-partial {
    &__alert-cookie-usage {
      height: 95px;
      padding: 0 25px;
      text-align: center;

      &__verbiage {
        font-size: 12px;
      }

      &__close {
        position: relative;
        top: 0;
        right: 0;
        padding: 7px 20px;
        margin-top: 5px;
        font-size: 12px;
      }
    }
  }
}

// **************************************************
// Small Viewport
// **************************************************
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .cookie-alert-partial {
    &__alert-cookie-usage {
      height: 95px;
      text-align: center;

      &__verbiage {
        font-size: 14px;
      }

      &__close {
        position: relative;
        top: 0;
        right: 0;
        padding: 10px 20px;
        margin-top: 5px;
      }
    }
  }
}

// **************************************************
// Medium Viewport
// **************************************************
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .cookie-alert-partial {
    &__alert-cookie-usage {
      &__verbiage {
        width: 80%;
      }

      &__close {
        top: -6px;
      }
    }
  }
}
</style>
