<!--suppress JSUnnecessarySemicolon -->
<template>
  <!-- <focus-trap :active="isShowModal" :initial-focus="() => $refs.init_focus"> -->
  <UseFocusTrap v-if="isShowModal" :options="{ immediate: false }">
    <div>
      <a ref="init_focus" style="font-size: 1px" tabindex="0" href="#">.</a>
      <el-dialog
        v-model="isShowModal"
        class="app-modal app-modal-secondary"
        :class="getBem(blockClass)"
        :title="modalTitle"
        top="3%"
        align-center
      >
        <!-- <a ref="init_focus" style="font-size: 1px" tabindex="-1" href="#">.</a> -->

        <!-- Only visible during Printing -->
        <div :class="getBem(blockClass, 'print-header')">
          <picture>
            <source
              class="brand-logo"
              srcset="@/assets/images/brand-logo.webp"
              type="image/webp"
              :alt="$t('digitaltolk_logo')"
              width="220"
              height="44"
            />
            <img
              class="brand-logo"
              src="@/assets/images/brand-logo.png"
              :alt="$t('digitaltolk_logo')"
              width="220"
              height="44"
            />
          </picture>
          <h1>{{ $t('booking_id_short') }} # {{ booking.id }}</h1>
        </div>

        <template v-if="isValidBooking && isShodowBooking">
          <div :class="getBem(blockClass, 'shadow-booking')">
            <el-card>
              <b :class="getBem(blockClass, 'shadow-booking-note')">
                {{ $t('customer_shadow_booking_note', { booking_id: booking.parent_booking_id }) }}
              </b>
            </el-card>
          </div>
        </template>

        <table
          v-if="isValidBooking"
          v-loading="is_waiting"
          class="app-booking-details-table"
          :class="getBem(blockClass, 'table')"
          style="border-collapse: collapse"
        >
          <tbody>
            <!-- Booking ID row -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_id_short') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.id }}
              </td>
            </tr>

            <!-- Reference row -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_reference') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.reference }}
              </td>
            </tr>

            <!-- Booker Name row -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_booker_name') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.booker_name }}
              </td>
            </tr>

            <!-- Additional Field Name row -->
            <tr v-if="isValidBookingAdditionalField(booking.additional_field)">
              <td :class="getBem(blockClass, 'table-label')">
                {{ additionalFieldLabelValue }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.additional_field }}
              </td>
            </tr>

            <!-- Additional Field Two Name row -->
            <tr v-if="isValidBookingAdditionalField(booking.additional_field_two)">
              <td :class="getBem(blockClass, 'table-label')">
                {{ additionalFieldTwoLabelValue }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.additional_field_two }}
              </td>
            </tr>

            <!-- Staff Name row -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_staff_name') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.staff_name }}
              </td>
            </tr>

            <!-- Staff phone row -->
            <!-- <tr> -->
            <!-- Staff phone row -->
            <tr v-if="!['convey', 'text_translation'].includes(booking.type)">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_staff_phone') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.staff_phone }}
              </td>
            </tr>

            <!-- Due row -->
            <tr v-if="booking.type === 'text_translation'">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('text_translation_select_delivery_date') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.due }}
              </td>
            </tr>

            <!-- Date -->
            <tr v-if="booking.type !== 'text_translation'">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('date') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.due_date }}
              </td>
            </tr>

            <!-- Start Time -->
            <tr v-if="booking.type !== 'text_translation'">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('start_time') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ bookingStartTime() }}
              </td>
            </tr>

            <!-- End Time -->
            <tr v-if="booking.type !== 'text_translation'">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('end_time') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ bookingEndTime() }}
              </td>
            </tr>

            <!-- Cancelled At row -->
            <tr v-if="isBookingCancelled">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('withdrawn_at') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.cancelled_at }}
              </td>
            </tr>

            <!-- Type row -->
            <tr v-if="!isBookingTypeVideo">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('type_of_interpretation') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ $t(`booking_type_${booking.type}`) }}
              </td>
            </tr>

            <!-- Text Translation Need stamp row -->
            <tr v-if="isBookingTypeTextTranslation">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('text_translation_need_to_be_stamp') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.text_translation.is_stamped ? $t('yes') : $t('no') }}
              </td>
            </tr>

            <!-- Text Translation Need stamp row -->
            <tr v-if="isBookingTypeTextTranslation">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('text_translation_need_to_be_stamp') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.text_translation.is_stamped ? $t('yes') : $t('no') }}
              </td>
            </tr>

            <!-- Address row -->
            <tr v-if="isBookingTypePhysical || isBookingTypeVideoPhysical">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('address') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.address }}
              </td>
            </tr>

            <!-- Instruction row -->
            <tr v-if="!['convey', 'text_translation', 'video_skype'].includes(booking.type)">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_address_instructions') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.instructions }}
              </td>
            </tr>

            <!-- Customer Name row -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('customer') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ bookingCustomer.name }}
              </td>
            </tr>

            <!-- Customer Mobile row -->
            <tr v-if="!isBookingTypeConvey">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('customer_mobile') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                <!--            <a :href="`tel:${ bookingCustomer.mobile }`">-->
                {{ bookingCustomer.mobile }}
                <!--            </a>-->
              </td>
            </tr>

            <!-- Customer Type row -->
            <tr v-if="!isBookingTypeConvey">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('customer_type') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ $t('customer_type_' + bookingCustomer.customer_data.customer_type.id) }}
              </td>
            </tr>

            <!-- Department row -->
            <tr v-if="!isBookingTypeConvey">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('department') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ bookingCustomer.customer_data.department.name }}
              </td>
            </tr>

            <!-- Municipality row -->
            <tr>
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('municipality') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ bookingCompanyMunicipality }}
              </td>
            </tr>

            <!-- Company row
      <tr>
        <td :class="getBem(blockClass, 'table-label')">
          {{ $t('company') }}
        </td>
        <td :class="getBem(blockClass, 'table-value')">
          {{ bookingCustomer.customer_data.department.company.name }}
        </td>
      </tr> -->

            <template v-if="isBookingWasTaken">
              <!-- Booking Session Time row -->
              <tr v-if="!isBookingTypeTextTranslation">
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('booking_session_time') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ booking.session_time }}
                </td>
              </tr>

              <!-- Booking comment row -->
              <tr v-if="!isBookingTypeTextTranslation">
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('booking_session_time') }} - {{ $t('comment') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')" :style="getWordStyle">
                  {{ booking.session_comment }}
                </td>
              </tr>
            </template>
            <!--- EndIf: If isBookingWasTaken defined --->

            <template v-if="isBookingWasTaken && translatorData(booking)">
              <!-- Assigned Translator ID row -->
              <tr
                v-if="
                  !isBookingTypeConvey ||
                  (isBookingTypeConvey && booking.status.code !== 'pending' && booking.status.code !== 'assigned')
                "
              >
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('tolk_id') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ translatorData(booking)?.translator_id }}
                </td>
              </tr>

              <!-- Assigned Translator Name row -->
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('translator_name') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ translatorData(booking).name }}
                </td>
              </tr>

              <!-- Assigned Translator Mobile row -->
              <tr v-if="!isBookingTypeTextTranslation" :class="getBem(blockClass, 'table-row-translator-mobile')">
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('translator_mobile') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <div v-if="isUserCustomer && isAllowNumberSettingsStatus" class="translator-mobile-long">
                    <i18n-t keypath="booking_details_translator_mobile">
                      <template #unified_number>
                        <a :href="`tel:${unifiedNumber}`">
                          <strong>{{ unifiedNumber }}</strong>
                        </a>
                      </template>
                      <template #booking_id>
                        <strong>{{ booking.id }}</strong>
                      </template>
                    </i18n-t>
                  </div>

                  <template v-else>
                    <a :href="`tel:${translatorData(booking).mobile}`">
                      {{ translatorData(booking).mobile }}
                    </a>
                  </template>
                </td>
              </tr>
            </template>
            <!--- EndIf: If assigned_translator defined --->

            <template v-if="isBookingWasTaken">
              <!-- Company Fields
          These fields gets loaded only when company settings are
          provided -->

              <!-- Translator DOB ROW -->
              <template v-if="booking.translator_dob != null">
                <tr>
                  <td :class="getBem(blockClass, 'table-label')">
                    {{ $t('translator_dob') }}
                  </td>
                  <td :class="getBem(blockClass, 'table-value')">
                    {{ booking.translator_dob }}
                  </td>
                </tr>
              </template>

              <!-- Translator DOB EMAIL -->
              <template v-if="booking.translator_email != null">
                <tr>
                  <td :class="getBem(blockClass, 'table-label')">
                    {{ $t('translator_email') }}
                  </td>
                  <td :class="getBem(blockClass, 'table-value')">
                    {{ booking.translator_email }}
                  </td>
                </tr>
              </template>

              <!-- Translator ADDRESS ROW -->
              <template
                v-if="
                  booking.translator_address != null &&
                  (!isBookingTypeConvey || (isBookingTypeConvey && booking.status.code !== 'assigned'))
                "
              >
                <tr>
                  <td :class="getBem(blockClass, 'table-label')">
                    {{ $t('translator_address') }}
                  </td>
                  <td :class="getBem(blockClass, 'table-value')">
                    {{ booking.translator_address }}
                  </td>
                </tr>
              </template>
              <!-- End of CompanyFields -->
            </template>
            <!--- EndIf: If isBookingWasTaken defined --->

            <!-- Language row -->
            <template v-if="isBookingTypeTextTranslation">
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('text_translation_from_language_id') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ languageOpts[booking.from_language_id] }}
                </td>
              </tr>
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('text_translation_to_language_id') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ languageOpts[booking.to_language_id] }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('language') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ languageOpts[booking.from_language_id] }}
                </td>
              </tr>
            </template>

            <!-- Video -->
            <template v-if="isBookingTypeVideo">
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('type_of_interpretation') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ $t(bookingVideo.provider) }}
                </td>
              </tr>
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('video_url') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <a :href="generateUrl(bookingVideo)" target="_blank">
                    {{ bookingVideo.provider === 'jitsi' ? $t('open_video_url') : bookingVideo.url }}
                  </a>
                </td>
              </tr>
            </template>

            <!-- Gender row -->
            <tr v-if="!isBookingTypeTextTranslation">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('gender') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ formatGender(booking.gender) }}
              </td>
            </tr>

            <template v-if="isBookingTypeTextTranslation">
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('text_translation_description') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ booking.text_translation.descriptions }}
                </td>
              </tr>
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('translator_level') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ bookingTranslatorLanguagePairLevel }}
                </td>
              </tr>
              <template v-if="isAskingPrice">
                <tr>
                  <td :class="getBem(blockClass, 'table-label')">
                    {{ $t('cost') }}
                  </td>
                  <td v-if="booking.text_translation.cost !== '0.00'" :class="getBem(blockClass, 'table-value')">
                    {{ booking.text_translation.cost }} kr
                    <br />
                    <el-button class="app-button-text text-color-primary" @click="handleAcceptPrice()">
                      <!-- <i class="el-icon-info actions-icon" /> -->
                      <el-icon class="actions-icon"> <InfoFilled /> </el-icon>{{ $t('accept_price') }}
                    </el-button>
                    <el-button class="app-button-text text-color-danger" @click="handleRejectPrice()">
                      <!-- <i class="el-icon-error actions-icon" /> -->
                      <el-icon class="actions-icon">
                        <CircleCloseFilled />
                      </el-icon>
                      {{ $t('reject_price') }}
                    </el-button>
                  </td>
                  <td v-else :class="getBem(blockClass, 'table-value')">
                    {{ $t('pricing_text_translation') }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td :class="getBem(blockClass, 'table-label')">
                    {{ $t('cost') }}
                  </td>
                  <td :class="getBem(blockClass, 'table-value')">
                    {{ booking.text_translation.cost }} kr
                    <br />
                  </td>
                </tr>
              </template>

              <template v-if="!empty(booking.text_translation.additional_text)">
                <tr>
                  <td :class="getBem(blockClass, 'table-label')">
                    {{ $t('text_translation_additional_text') }}
                  </td>
                  <td :class="getBem(blockClass, 'table-value')">
                    {{ booking.text_translation.additional_text }}
                  </td>
                </tr>
              </template>

              <template
                v-if="
                  !empty(booking.text_translation.translated_text) &&
                  booking.text_translation.status_code === 'completed'
                "
              >
                <tr>
                  <td :class="getBem(blockClass, 'table-label')">
                    {{ $t('text_translation_translated_text') }}
                  </td>
                  <td :class="getBem(blockClass, 'table-value')">
                    {{ booking.text_translation.translated_text }}
                  </td>
                </tr>
              </template>

              <!-- Text Translation Files -->
              <tr v-if="!empty(textTranslationReviewFiles)">
                <td :class="getBem(blockClass, 'table-label')">
                  <!-- <i class="el-icon-document" />  -->
                  <el-icon><Document /></el-icon>{{ $t('text_translation_review_file') }}
                </td>
                <template v-if="isUserCustomer || !isTransTargetBooking">
                  <td :class="getBem(blockClass, 'table-value')">
                    <div v-for="item in textTranslationReviewFiles" :key="item.id" class="fLists">
                      <div class="item">
                        <span class="text-color-success text-underline">
                          <!-- <i class="el-icon-document-copy" /> -->
                          <el-icon><DocumentCopy /></el-icon>
                          {{ addDotsForLongerFileName(item.display_name, 24) }}
                        </span>
                      </div>
                      <div class="options">
                        <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                          <svg
                            width="15px"
                            height="15px"
                            fill="#555555"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path
                              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                            />
                          </svg>
                        </el-button>
                        <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                          <svg
                            width="15px"
                            height="15px"
                            fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path
                              d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                            />
                          </svg>
                        </el-button>
                      </div>
                    </div>
                  </td>
                </template>
                <template v-if="isTransTargetBooking && isUserTranslator">
                  <td :class="getBem(blockClass, 'table-value')">
                    <div class="item row">
                      <el-button
                        type=""
                        size="medium"
                        color="black"
                        class="text-color-white link"
                        @click="handleFileClick(textTranslationReviewFiles[0], true)"
                      >
                        <img src="@/assets/images/external-link.png" :alt="$t('trans-target-link')" />
                        {{ $t('tt_label_review_document') }}
                      </el-button>
                      <el-button type="" size="medium" color="white" class="text-color-black learn-more-btn">
                        {{ $t('tt_label_learn_more') }}
                      </el-button>
                    </div>
                  </td>
                </template>
              </tr>
              <tr v-if="!empty(textTranslationCompletedFiles)">
                <td :class="getBem(blockClass, 'table-label')">
                  <!-- <i class="el-icon-document-checked" /> -->
                  <el-icon><DocumentChecked /></el-icon>
                  {{ $t('completed_file') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <div v-for="item in textTranslationCompletedFiles" :key="item.id" class="fLists">
                    <div class="item">
                      <span class="text-color-success text-underline">
                        <!-- <i class="el-icon-document-copy" /> -->
                        <el-icon><DocumentCopy /></el-icon>
                        {{ addDotsForLongerFileName(item.display_name, 24) }}
                      </span>
                    </div>
                    <div class="options">
                      <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#555555"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                          />
                        </svg>
                      </el-button>
                      <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#ffffff"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                          />
                        </svg>
                      </el-button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <!-- Translator Levels -->
              <tr
                v-if="
                  !isBookingTypeConvey ||
                  (isBookingTypeConvey && booking.status.code !== 'pending' && booking.status.code !== 'assigned')
                "
              >
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('translator_levels') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <ul :class="getBem(blockClass, 'table-value-translator-levels')">
                    <li v-for="(level, idx) in booking.translator_levels" :key="idx">
                      {{ formatTranslatorLevel(level.translator_level_id) }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr v-if="isValidTransparencyValuesTranslatorLevel && isBookingWasTaken">
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('transparency_values_translator_level') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ formatTransparencyValuesTranslatorLevel(booking.transparency_values) }}
                </td>
              </tr>
            </template>

            <!-- Specific Translator row -->
            <tr v-if="hasSpeficicTranslators">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_specific_translator') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                <translator-picker-field v-model="specificTranslators" :disabled="true" />
              </td>
            </tr>

            <!-- Excluded Translator row -->
            <tr v-if="hasExcludedTranslators">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('booking_excluded_translator') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                <translator-picker-field v-model="excludedTranslators" :disabled="true" />
              </td>
            </tr>

            <!-- convey booking details -->
            <template v-if="isBookingTypeConvey && isValidConveyBooking">
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('message_to_convey') }}
                </td>
                <td class="wordArrange" :class="getBem(blockClass, 'table-value')">
                  {{ booking.convey.message }}
                </td>
              </tr>
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('additional_info') }}
                </td>
                <td class="wordArrange" :class="getBem(blockClass, 'table-value')">
                  {{ booking.convey.additional_info }}
                </td>
              </tr>
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('patient_phone') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <a :href="`tel:${booking.convey.phone}`">{{ booking.convey.phone }}</a>
                </td>
              </tr>
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('convey_files') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <div v-for="item in booking.convey.files" :key="item.id" class="fLists">
                    <div class="item">
                      <span class="text-color-success text-underline">
                        <!-- <i class="el-icon-document-copy" /> -->
                        <el-icon><DocumentCopy /></el-icon>
                        {{ addDotsForLongerFileName(item.display_name, 24) }}
                      </span>
                    </div>
                    <div class="options">
                      <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#555555"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                          />
                        </svg>
                      </el-button>
                      <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#ffffff"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                          />
                        </svg>
                      </el-button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>

            <!-- Booking Message -->
            <template v-if="hasMessage">
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('booking_message') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  {{ booking.messages.message === '----' ? '' : booking.messages.message }}
                </td>
              </tr>

              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('message_files') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <div v-for="item in booking.messages.files" :key="item.id" class="fLists">
                    <div class="item">
                      <span class="text-color-success text-underline">
                        <!-- <i class="el-icon-document-copy" /> -->
                        <el-icon><DocumentCopy /></el-icon>
                        {{ addDotsForLongerFileName(item.display_name, 24) }}
                      </span>
                    </div>
                    <div class="options">
                      <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#555555"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                          />
                        </svg>
                      </el-button>
                      <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#ffffff"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                          />
                        </svg>
                      </el-button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>

            <!-- list all booking issue files -->
            <template v-if="!empty(issueFiles) && isUserCustomer">
              <tr>
                <td :class="getBem(blockClass, 'table-label')">
                  {{ $t('booking_issue_files') }}
                </td>
                <td :class="getBem(blockClass, 'table-value')">
                  <div v-for="item in issueFiles" :key="item.id" class="fLists">
                    <div class="item">
                      <span class="text-color-success text-underline">
                        <!-- <i class="el-icon-document-copy" /> -->
                        <el-icon><DocumentCopy /></el-icon>
                        {{ addDotsForLongerFileName(item.display_name, 24) }}
                      </span>
                    </div>
                    <div class="options">
                      <el-button type="default" size="small" class="btn-xs" @click="openFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#555555"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                          />
                        </svg>
                      </el-button>
                      <el-button type="primary" size="small" class="btn-xs" @click="downloadSelectedFile(item)">
                        <svg
                          width="15px"
                          height="15px"
                          fill="#ffffff"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
                          />
                        </svg>
                      </el-button>
                    </div>
                  </div>
                </td>
              </tr>
            </template>

            <tr v-if="canShowTrainings">
              <td :class="getBem(blockClass, 'table-label')">
                {{ $t('required_course') }}
              </td>
              <td :class="getBem(blockClass, 'table-value')">
                {{ booking.translator_trainings.map((i) => i.name).join(', ') }}
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <div :class="getBem(blockClass, 'actions')" class="text-right">
          <el-button
            class="app-button-secondary"
            :class="getBem(blockClass, 'print-button')"
            :icon="printerIcon"
            @click="handleClickPrint"
          >
            {{ $t('btn_print') }}
          </el-button>

          <el-button class="app-button-default" :class="getBem(blockClass, 'close-button')" @click="handleClickClose">
            {{ $t('btn_close') }}
          </el-button>
        </div>
      </el-dialog>
      <!-- Component DIV Wrapper -->
    </div>
  </UseFocusTrap>
  <!-- </focus-trap> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import findKey from 'lodash/findKey'
import includes from 'lodash/includes'
import map from 'lodash/map'
import getTranslatorLevels from '@/modules/constants/translatorLevel'
import TranslatorPickerField from '@/modules/auth/components/forms/TranslatorPickerField'
import { formatTime } from '@/modules/helpers/common'
//import BookingTimeSlots from '../partials/BookingTimeSlots'

import API, { TRANS_TARGET_URL } from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { download, showFile } from '@/modules/helpers/file'

import { Printer } from '@element-plus/icons-vue'

import LS from '@/modules/constants/localStorage'
import appStorage from '@/modules/helpers/appStorage'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > imported components
  |--------------------------------------------------------------------------
  */
  components: {
    //BookingTimeSlots,
    TranslatorPickerField
  },

  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    title: {
      type: String,
      default: ''
    }
  },

  emits: ['update-list'],

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'customer-booking-details-modal',
      isShowModal: false,
      booking: {},
      specificTranslators: [],
      excludedTranslators: [],
      issueFiles: [],
      is_waiting: false,
      log: console.log,
      printerIcon: Printer,
      transTargetUrl: TRANS_TARGET_URL
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('booking', ['languageOpts', 'bcfConfig']),

    ...mapGetters('auth', ['translatorOpts', 'isUserCustomer', 'isUserTranslator', 'user']),

    getWordStyle() {
      return {
        'word-break': 'normal'
      }
    },

    /**
     * @returns {string}
     */
    modalTitle() {
      return this.title !== '' ? this.title : this.$t('booking_details')
    },

    /**
     * @returns {string}
     */
    bookingCompanyMunicipality() {
      return (
        this.booking?.customer?.department?.company?.municipality?.name ??
        this.booking?.customer?.customer_data?.department?.company?.municipality?.name
      )
    },

    /**
     * Returns boolean whether the booking details is valid to be displayed.
     *
     * @return {Boolean}
     */
    isValidBooking() {
      return !isEmpty(this.booking)
    },

    /**
     * Returns boolean whether the convey booking details is valid to be displayed.
     *
     * @return {Boolean}
     */
    isValidConveyBooking() {
      return !isEmpty(this.booking.convey)
    },

    /**
     * Returns boolean whether if the booking was entertained by the Translator at some point.
     *
     * @returns {Boolean}
     */
    isBookingWasTaken() {
      return (
        this.booking.status.code !== 'pending' &&
        this.booking.status.code !== 'to_be_planned' &&
        this.booking.status.code !== 'expired' &&
        this.booking.status.code !== 'cancelled' &&
        this.booking.status.code !== 'late_cancelled' &&
        this.booking.status.code !== 'late_cancelled_customer'
      )
    },

    isBookingAccepted() {
      return this.booking.status.code === 'accepted' && this.booking.status.code === 'started'
    },

    /**
     * Returns boolean whether if the booking type is video.
     *
     * @returns {boolean}
     */
    isBookingTypeVideo() {
      return ['video', 'video_skype', 'video_emergency'].includes(this.booking.type)
    },

    /**
     * Returns boolean whether if the booking type is physical.
     *
     * @returns {boolean}
     */
    isBookingTypePhysical() {
      return String(this.booking.type) === 'physical'
    },

    /**
     * Returns boolean whether if the booking type is phone.
     *
     * @returns {boolean}
     */
    isBookingTypePhone() {
      return String(this.booking.type) === 'phone'
    },

    /**
     * Returns the fail proof value for booking.video_booking.
     *
     * @returns {object}
     */
    bookingVideo() {
      if (!isNil(this.booking) && !isNil(this.booking.video_booking) && !isEmpty(this.booking.video_booking)) {
        return this.booking.video_booking
      } else {
        // Define value placeholders in here.
        return {}
      }
    },

    /**
     * Returns boolean if the booking was cancelled.
     *
     * @returns {Boolean}
     */
    isBookingCancelled() {
      return !isNil(this.booking.cancelled_at)
    },

    /**
     * Computed value for booking customer.
     * @returns {*}
     */
    bookingCustomer() {
      return isArray(this.booking.customer) ? this.booking.customer[0] : this.booking.customer
    },

    /**
     * Returns value to determine if the booking has speficic translator assigned.
     *
     * @returns {boolean}
     */
    hasSpeficicTranslators() {
      return !isNil(this.booking.specific_translators) && !isEmpty(this.booking.specific_translators)
    },

    /**
     * Value to determine if the current target booking status has exclude translator.
     *
     * @return {boolean}
     */
    hasExcludedTranslators() {
      return !isNil(this.booking.excluded_translators) && !isEmpty(this.booking.excluded_translators)
    },

    /**
     * Returns value to determine if the number settings status under
     * booking.customer.department is set to "allowed".
     *
     * @returns {boolean}
     */
    isAllowNumberSettingsStatus() {
      return (
        this.isValidBooking &&
        !isNil(this.booking) &&
        !isNil(this.booking.customer) &&
        !isNil(this.booking.customer.department) &&
        !isEmpty(this.booking.customer.department) &&
        this.booking.customer.department.number_settings_status === 'allowed'
      )
    },

    /**
     * Returns the unified number value under booking.customer.department.
     *
     * @return {number}
     */
    unifiedNumber() {
      return this.isAllowNumberSettingsStatus ? this.booking.customer.department.number_settings_number : 0
    },

    /**
     * Returns value to determine if the booking has speficic message and files.
     *
     * @returns {boolean}
     */
    hasMessage() {
      return !isNil(this.booking.messages) && !isEmpty(this.booking.messages)
    },

    /**
     * Returns boolean whether if the booking type is convey.
     *
     * @returns {boolean}
     */
    isBookingTypeConvey() {
      return String(this.booking.type) === 'convey'
    },
    /*
     * Returns boolean whether if the booking type is text translation.
     *
     * @returns {boolean}
     */
    isBookingTypeTextTranslation() {
      return String(this.booking.type) === 'text_translation'
    },

    /**
     * Returns boolean whether if the booking type is text translation trans target.
     *
     * @returns {boolean}
     */
    isTransTargetBooking() {
      return this.isBookingTypeTextTranslation && this.booking?.text_translation?.is_trans_target
    },

    /**
     * Returns boolean whether if the booking is flaged asking price.
     *
     * @returns {boolean}
     */
    isAskingPrice() {
      return (
        this.isBookingTypeTextTranslation &&
        this.booking.text_translation.is_asking_price &&
        ![4, 5, 6, 7, 8, 9, 10, 12, 13, 21, 22].includes(this.booking.status_id)
      )
    },

    /**
     * Returns boolean whether if the booking type is video physical.
     *
     * @returns {boolean}
     */
    isBookingTypeVideoPhysical() {
      return String(this.booking.type) === 'video_physical'
    },

    /**
     *
     **/
    textTranslationReviewFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        // return find(this.booking.text_translation.files, function(o){ return o.pivot.submission_type === 'review'} )
        return this.booking.text_translation.files.filter(function (o) {
          return o.pivot.submission_type === 'review'
        })
      }

      return []
    },

    /**
     *
     **/
    textTranslationCompletedFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        // return find(this.booking.text_translation.files, function(o){ return o.pivot.submission_type === 'review'} )
        return this.booking.text_translation.files.filter(function (o) {
          return o.pivot.submission_type === 'completed'
        })
      }

      return []
    },

    /**
     * @returns {boolean}
     */
    isValidTransparencyValues() {
      return !isNil(this.booking) && !isNil(this.booking.transparency_values)
    },

    /**
     * @returns {boolean}
     */
    isValidTransparencyValuesTranslatorLevel() {
      return this.isValidTransparencyValues && !isNil(this.booking.transparency_values.translator_level)
    },
    /**
     * Returns boolean whether if it's a shadow booking.
     *
     * @returns {Boolean}
     */
    isShodowBooking() {
      return this.booking.parent_booking_id != null
    },
    /**
     * Returns boolean whether if booking has specific trainings.
     *
     * @returns {Boolean}
     */
    canShowTrainings() {
      return (
        !this.isBookingTypeTextTranslation &&
        !isNil(this.booking.translator_trainings) &&
        this.booking.translator_trainings.length > 0
      )
    },
    additionalFieldLabelValue() {
      if (!isEmpty(this.booking.additional_field_label)) return this.booking.additional_field_label
      if (!isEmpty(this.bcfConfig.additional_field_label)) return this.bcfConfig.additional_field_label
      return 'Additional Field'
    },
    additionalFieldTwoLabelValue() {
      if (!isEmpty(this.booking.additional_field_two_label)) return this.booking.additional_field_two_label
      if (!isEmpty(this.bcfConfig.additional_field_two_label)) return this.bcfConfig.additional_field_two_label
      return 'Additional Field Two'
    },

    bookingTranslatorLanguagePairLevel() {
      if (!isEmpty(this.booking.language_pair_levels)) {
        return this.booking.language_pair_levels
          .map((languagePairLevel) => this.translateLanguagePairLevelCode(languagePairLevel.code))
          .toString()
      }

      return ''
    },
    /**
     * @return {String}
     */
    authToken() {
      const appToken = appStorage.get(LS.TOKEN)
      const token = appToken.access_token ?? appToken
      return token
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapActions('textTranslation', ['downloadFile', 'acceptPrice', 'rejectPrice']),
    ...mapActions('booking', ['getBookingIssueFiles', 'getBookingDetails']),

    formatTime,
    downloadSelectedFile(item) {
      this.is_waiting = true
      let o = {
        params: `path=${item.path}&display_name=${item.display_name}`,
        filename: item.display_name
      }

      let url = `${API.STORAGE}/get?${o.params}`

      return axiosInstance.get(url).then((r) => {
        this.forceDownloadFileUrl(r.data.data.url, o.filename)
      })
    },

    /**
     * Helper methodss for handling download files to s3
     */
    openFile(item) {
      showFile(item)
    },

    forceDownloadFileUrl(url, filename) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          this.is_waiting = false
        })
        .catch(console.error)
    },

    addDotsForLongerFileName(string, limit) {
      let dots = '...'
      let fileExt = string.split('.')
      if (fileExt[0].length > limit) {
        // you can also use substr instead of substring
        string = fileExt[0].substring(0, limit) + dots + fileExt[1]
      }

      return string
    },

    bookingStartTime() {
      return this.booking?.due_time ?? ''
    },

    bookingEndTime() {
      let willEndAt = this.booking?.will_end_at ?? ''
      let endedAT = this.booking?.ended_at ?? ''
      let endTime = endedAT ? endedAT : willEndAt
      return endTime ? this.formatTime(endTime) : ''
    },

    /**
     * Handler when the Print button was clicked.
     *
     * @returns {void}
     */
    handleClickPrint() {
      if (!isNil(window) && !isNil(window.print) && isFunction(window.print)) {
        window.print()
      }
    },

    /**
     * Handler when the Close button was clicked.
     *
     * @returns {void}
     */
    handleClickClose() {
      this.close()
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @params {object} booking - current target booking.
     * @returns {void}
     */
    open(booking) {
      if (isNil(booking)) return
      this.getBookingDetails({ id: booking.id, bookingStatus: booking.status.code }).then((response) => {
        this.booking = response.data.data.booking
        this.specificTranslators = this.formatSpecificTranslators(this.booking.specific_translators)
        this.excludedTranslators = this.formatSpecificTranslators(this.booking.excluded_translators)
        this.isShowModal = true

        //only request this when booking is completed
        if (booking.status_id === 4) {
          this.getBookingIssueFiles({
            id: booking.id,
            params: { type: 'low_feedback' }
          }).then((r) => {
            this.issueFiles = r.data.data
          })
        }
      })
    },

    /**
     * Method to be used outside of this component via $ref.
     * This will close the modal.
     *
     * @returns {void}
     */
    close() {
      this.isShowModal = false
      this.$emit('close')
    },

    /**
     * Helper method for formatting Translator level values.
     *
     * @param {int|string} levelId - value to be formatted.
     * @returns {string}
     */
    formatTranslatorLevel(levelId) {
      // Attempt to find the equivalent key.
      const key = findKey(getTranslatorLevels(), (id) => id === parseInt(levelId))

      if (!isNil(key)) {
        // If Key exists, return the translated verbiage.
        return this.$t(`translator_level_${key.toLowerCase()}`)
      }

      // else {
      //   throw new Error('Key finding failed.');
      // }
      return ''
    },

    /**
     * Helper method for formatting the gender value to be
     * able to display it properly.
     *
     * @param {string|*} gender
     * @returns {string}
     */
    formatGender(gender) {
      return gender === null || includes(gender, ',') ? `${this.$t('male')}, ${this.$t('female')}` : this.$t(gender)
    },

    /**
     * Helper method for formatting the gender value to be
     * able to display it properly.
     *
     * @param {array} specificTranslators
     * @returns {array}
     */
    formatSpecificTranslators(specificTranslators) {
      if (!isNil(specificTranslators) && !isEmpty(specificTranslators)) {
        return map(specificTranslators, (o) => o.translator_id)
      } else {
        return []
      }
    },

    /**
     * @param {object} transparencyValues
     * @returns {string}
     */
    formatTransparencyValuesTranslatorLevel(transparencyValues) {
      if (
        !isNil(transparencyValues) &&
        !isEmpty(transparencyValues) &&
        !isNil(transparencyValues.translator_level) &&
        !isNil(transparencyValues.translator_level.code)
      ) {
        return this.$t(`translator_level_${transparencyValues.translator_level.code}`)
      } else {
        return ''
      }
    },

    /**
     * Helper methodss for handling download files to s3
     */
    handleFileClick(item) {
      if (this.isUserTranslator && this.isTransTargetBooking) {
        let storedLocale = localStorage.getItem('app_language')
        storedLocale = !isNil(storedLocale) ? storedLocale : 'se'
        window.open(
          `${this.transTargetUrl}/go-to-booking?booking_id=${this.booking.id}&locale=${storedLocale}&token=${this.authToken}`,
          '_blank'
        )
        return
      }
      download(item)
    },

    /**
     * method for customer accepting a text translation cost
     */
    handleAcceptPrice() {
      let payload = {
        id: this.booking.text_translation.id
      }

      this.acceptPrice(payload).then((r) => {
        console.log(r)
        this.$emit('updateList')
        this.close()
      })
    },

    /**
     * method for customer reject a text translation cost
     */
    handleRejectPrice() {
      let payload = {
        id: this.booking.text_translation.id
      }

      this.rejectPrice(payload).then((r) => {
        console.log(r)
        this.$emit('updateList')
        this.close()
      })
    },
    generateUrl(bookingVideo) {
      if (bookingVideo.url?.substring(0, 4) === 'http') {
        return bookingVideo.url
      } else {
        return '//' + bookingVideo.url
      }
    },

    /**
     * helpers to check file is empty or not
     */
    empty(v) {
      return isEmpty(v) || isNil(v)
    },

    /**
     * @return {boolean}
     */
    isValidBookingAdditionalField(additionalFieldValue) {
      return !isNil(additionalFieldValue) && additionalFieldValue !== ''
    },
    translatorData(booking) {
      if (booking.status.code == 'planned') {
        return booking.planned_translator.translator
      } else {
        return booking.assigned_translator
      }
    },

    /**
     * @return {string}
     */
    translateLanguagePairLevelCode(code) {
      switch (code) {
        case 'certified':
          return this.$t('pair_level_certified_interpreter')
        case 'senior':
          return this.$t('pair_level_senior_interpreter')
        case 'non-certified':
          return this.$t('pair_level_non_certified_interpreter')
        default:
          return code
      }
    }
  }
}
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';

.customer-booking-details-modal {
  a,
  a:hover,
  a:focus,
  a:visited,
  a:active {
    color: var(--app-primary-color);
    text-decoration: underline;
  }

  &__table {
    width: 80%;
    margin: 0 auto;

    td {
      padding-top: 10px;
      padding-bottom: 10px;

      span {
        word-break: break-word;
      }

      .translator-mobile-long span {
        font-size: 12px;
      }
    }

    &-label {
      word-break: break-word;
    }

    &-value {
      &-translator-levels,
      &-translator-levels > li {
        font-size: 11px;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .el-button.link {
        padding: 5px 20px !important;
        img {
          margin-right: 4px;
          width: 18px;
        }
      }

      &-translator-levels > li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .translator-picker-field {
        .el-select__tags {
          max-width: none;
          justify-content: center;

          &-text {
            color: $app-form-gray;
          }

          .el-tag {
            border: 0;
            background-color: transparent;
          }
        }

        .el-input__inner {
          border: 0;
          background-color: transparent;
          padding: 0;
          text-align: center;
        }

        .el-select__input {
          display: none;
        }
      }

      .file-list {
        list-style: none;
      }
    }
  }

  &__shadow-booking {
    &-note {
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}

// **************************************************
// Extra Small Viewport
// **************************************************
@media (max-width: $screen-xs-max) {
  .customer-booking-details-modal {
    .el-dialog {
      width: 90%;
    }
  }
}

// **************************************************
// Small Viewport
// **************************************************
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .customer-booking-details-modal {
    .el-dialog {
      width: 90%;
    }
  }
}

// **************************************************
// Medium Viewport
// **************************************************
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .customer-booking-details-modal {
    .el-dialog {
      width: 75%;
    }
  }
}

@include print-booking-details('customer');

.text-right {
  text-align: right !important;
}
.wordArrange {
  word-break: normal;
}
</style>
