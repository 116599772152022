/*
|--------------------------------------------------------------------------
| Custom > Constants > Event
|--------------------------------------------------------------------------
|
| This file contains the constants identifiers or key references
| for Pusher Events.
|
*/

// BOOKING EVENTS
export const BOOKING_CREATED = 'booking.created'
export const BOOKING_UPDATED = 'booking.updated'
export const BOOKING_POTENTIAL = 'booking.potential'
export const BOOKING_NOT_MATCH = 'booking.not-matching'

export const BOOKING_ACCEPTED = 'booking.accepted'
export const BOOKING_ACCEPTED_BY_ADMIN = 'booking.accepted.by.admin'

export const BOOKING_STARTED_REMINDER = 'booking.started.reminder'
export const BOOKING_STARTED = 'booking.started'

export const BOOKING_WITHDRAWN = 'booking.withdrawn'
export const BOOKING_LATE_CANCEL = 'booking.late_cancel'

export const BOOKING_CANCELLED = 'booking.cancelled'
export const BOOKING_EXPIRED = 'booking.expired'
export const BOOKING_ENDED = 'booking.ended'

export const BOOKING_FEEDBACK_REMINDER = 'booking-feedback.reminder'
export const BOOKING_SESSION_REMINDER = 'booking-report-session.reminder'

export const BOOKING_FEEDBACK_CREATED = 'feedback.created'
export const BOOKING_SESSION_UPDATED = 'translator.update.session'

export const BOOKING_EXPIRED_FROM_COUNTER = 'booking.expired.counter'
export const USER_BOOKING_STATISTICS = 'user.booking.statistic'
export const TRANSLATOR_TRAVEL_TIME_REJECTED = 'translator.travel-time.rejected'
export const TRANSLATOR_TRAVEL_TIME_ACCEPTED = 'translator.translator.travel-time.accepted'

// TEXT TRANSLATION BOOKING EVENTS
export const TEXT_TRANSLATION_CAN_RESUBMIT = 'text-translation.can.resubmit'
export const TEXT_TRANSLATION_BOOKING_COMPLETED = 'text-translation.completed'
export const TEXT_TRANSLATION_BOOKING_DEADLINE_REMINDER = 'text-translation.deadline-reminder'
export const TEXT_TRANSLATION_COST_SUBMISSION = 'text.translation.cost.submission'
export const TEXT_TRANSLATION_FILE_RESUBMITTED = 'text_translation.translator_resubmitted'

// WARNING/PENALTY EVENTS
export const BOOKING_NOT_PICKING_UP_WARNING = 'booking.not-pickup-warning'
export const BOOKING_PENALTY = 'booking.penalty'

// OTHER EVENTS
export const SURVEY_CREATED = 'survey.created'
export const BLOG_CREATED = 'blog.created'
export const USER_INBOX = 'ticket.created'

// ACTION MANAGER EVENTS
export const BOOKING_ISSUE_EVENTS_REMINDER = 'booking-issue.events.reminder'
export const BOOKING_ISSUE_CUSTOMER_REPORT = 'booking-issue.customer.report'

// MARK AS READ EVENTS
export const NOTIFICATION_MARK_SINGLE_READ = 'notification.read.single'
export const NOTIFICATION_MARK_ALL_REGULAR_READ = 'notification.read.all.regular'
export const NOTIFICATION_MARK_ALL_INBOX_READ = 'notification.read.all.inbox'

// LEAVE REQUEST EVENTS
export const LEAVE_REQUEST_APPROVED = 'leave-request.approved'
export const LEAVE_REQUEST_REJECTED = 'leave-request.rejected'
export const INVOICE_COMPRESSED = 'invoice-compressed'
export const FE_VERSIONING = 'fe-versioning'
export const FE_VERSIONING_UPDATED = 'fe-version-updated'

export default {
  BOOKING_CREATED,
  BOOKING_UPDATED,
  BOOKING_ACCEPTED,
  BOOKING_ACCEPTED_BY_ADMIN,
  BOOKING_STARTED_REMINDER,
  BOOKING_STARTED,
  BOOKING_WITHDRAWN,
  BOOKING_LATE_CANCEL,
  BOOKING_CANCELLED,
  BOOKING_EXPIRED,
  BOOKING_ENDED,
  BOOKING_FEEDBACK_REMINDER,
  BOOKING_SESSION_REMINDER,
  BOOKING_FEEDBACK_CREATED,
  BOOKING_SESSION_UPDATED,
  BOOKING_EXPIRED_FROM_COUNTER,
  USER_BOOKING_STATISTICS,
  BOOKING_NOT_PICKING_UP_WARNING,
  BOOKING_PENALTY,
  SURVEY_CREATED,
  BLOG_CREATED,
  USER_INBOX,
  BOOKING_ISSUE_EVENTS_REMINDER,
  BOOKING_ISSUE_CUSTOMER_REPORT,
  NOTIFICATION_MARK_SINGLE_READ,
  NOTIFICATION_MARK_ALL_REGULAR_READ,
  NOTIFICATION_MARK_ALL_INBOX_READ,
  TEXT_TRANSLATION_CAN_RESUBMIT,
  TEXT_TRANSLATION_BOOKING_COMPLETED,
  TEXT_TRANSLATION_BOOKING_DEADLINE_REMINDER,
  TEXT_TRANSLATION_COST_SUBMISSION,
  BOOKING_POTENTIAL,
  BOOKING_NOT_MATCH,
  LEAVE_REQUEST_APPROVED,
  LEAVE_REQUEST_REJECTED,
  TEXT_TRANSLATION_FILE_RESUBMITTED,
  INVOICE_COMPRESSED,
  FE_VERSIONING,
  FE_VERSIONING_UPDATED
}
