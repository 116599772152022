/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadCustomerHistoricBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { bookingsListFilterOptions, loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store'

/**
 * Action to load customer historic bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {int} payload.page - The page offset to be displayed.
 * @param {int} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  context.commit('setCustomerHistoricBookingListLoading', true)

  const url = `${API.BOOKINGS}/historic`
  const method = 'GET'

  const include = [
    // 'customer',
    'feedback',
    'translator_levels',
    'assigned_translator',
    // 'notification_types',
    'specific_translators',
    // 'excluded_translators',
    'video_booking',
    // 'messages.files',
    // 'convey.files',
    // 'text_translation.files',
    // 'towns',
    // 'translator_trainings',
    'booking_time_slots.translator.translator_unavailable_times',
    'deviation_report'
  ].join(',')

  const fields = [
    'id,customer_id,due,duration,from_language_id,status_id,is_immediate,gender,type,created_at,reference,booker_name,additional_field_label,additional_field,additional_field_two_label,additional_field_two,staff_name,instructions,address,session_time,ended_at,will_end_at,parent_booking_id,town_id,staff_phone'
  ].join(',')

  const topControls = await store.getters['bookingList/topControls']
  let statuses = topControls?.booking_statuses?.toString()
  let excludeOptions = {}

  if (topControls?.show_department_bookings || store.getters['auth/isPurchasingManager']) {
    excludeOptions = {
      exclude: ['customer_id']
    }
  }

  let options = {
    query_params: {
      'fields[bookings]': fields,
      include,
      // append: 'transparency_values',
      // sort: '-id,-due',
      'filter[status_id]': statuses
    },
    on_finally(ctx) {
      ctx.commit('setCustomerHistoricBookingListLoading', false)
      ctx.commit('setBookingFilterQueryForExport', assignIn(options.query_params, filterQueries))
    }
  }
  // will only be applied if user is purchase manager otherwise ignored.
  options.query_params['pm_filter[pm_sector_type]'] = store.getters['bookingList/purchaseManagerSectorType']
  options.query_params['pm_filter[pm_sector_id]'] = store.getters['bookingList/purchaseManagerSectorId']

  if (payload?.includeBookingType) {
    options.query_params['filter[type]'] = payload.includeBookingType
  }

  let emergency = topControls?.is_immediate ?? payload?.is_immediate ?? null

  if (emergency) {
    options.query_params['filter[is_immediate]'] = emergency
  } else {
    delete options.query_params['filter[is_immediate]']
  }
  let type = topControls?.type ?? payload?.type ?? null
  if (type) {
    payload.filter_list_query = type
  }

  const filterQueries = bookingsListFilterOptions(payload, excludeOptions)

  assignIn(options.query_params, filterQueries)

  // fix for CS-741
  if (options.query_params['filter[id]'] !== undefined && options.query_params['filter[id]'] !== null) {
    delete options.query_params['filter[status_id]']
  }

  return loadAll(this, context, url, method, 'setCustomerHistoricBookingList', options, axiosInstance)
}
