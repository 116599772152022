import getPaymentMethods from './actions/getPaymentMethods'
import getTransactionHistory from './actions/getTransactionHistory'
import deletePaymentMethod from './actions/deletePaymentMethod'
import makePaymentMethodDefault from './actions/makePaymentMethodDefault'
export default {
  namespaced: true,
  state: {
    provider: import.meta.env.VITE_PAYMENT_PROVIDER,
    stripe: {
      public_key: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
      service_fee_percentage: 1.5,
      service_fee_fixed: 1.8
    },
    paymentMethods: [],
    transactionHistory: [],
    defaultPaymentMethodId: null,
    paymentMethodType: 'stripe'
  },
  mutations: {
    setPaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods
    },
    setTransactionHistory(state, transactionHistory) {
      state.transactionHistory = transactionHistory
    },
    setDefaultPaymentMethodId(state, defaultPaymentMethodId) {
      state.defaultPaymentMethodId = defaultPaymentMethodId
    },
    setPaymentMethodType(state, paymentMethodType) {
      state.paymentMethodType = paymentMethodType
    }
  },
  actions: {
    getPaymentMethods,
    getTransactionHistory,
    deletePaymentMethod,
    makePaymentMethodDefault
  }
}
